import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Bienvenue sur le blog de Lokimo.
    </Text>
    <p>{`Le monde de l’immobilier repose sur la donnée mais ne bénéficie pas des dernières technologies en la matière. LokimoAI agrège périodiquement de façon automatique des données variées (analyse d’annonces immobilières, d’articles de presses, dvf, insee etc ..) et les combine avec des techniques d’intelligence artificielle et de Big Data pour proposer des renseignements inédits comme de la prédiction d'évolution de prix sur 3 ans. Sous la forme d’un site internet à destination des professionnels, toutes ces données sont visualisables, recherchables et croisables pour chaque zone de France avec la possibilité d’en créer des rapports.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      